import styled from 'styled-components';
import { breakPoints, colors, fontWeight } from 'utils/variables';
import Image from 'components/_shared/Image/Image';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 4rem 0 10rem 0;
  max-width: 75rem;
  width: 100%;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 1600px;
    flex-direction: row;
    padding: 14.5rem 0 12rem 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 14.5rem 0 19.7rem 0;
  }
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    transform: rotate(-5deg);
    height: 68rem;
    width: 69rem;
    left: -51rem;
    top: 9rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    left: -18.4rem;
    top: 36.6rem;
    height: 50.5rem;
    width: 36.5rem;
  }
`;

export const StyledMap = styled(Image)`
  width: 100%;
  height: 100%;
`;

export const StyledContent = styled.div`
  margin: 3rem auto 0 auto;
  width: 63.6rem;
  max-width: 100%;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    width: 58.8rem;
    text-align: left;
    margin: 9.6rem 0 0 6.8rem;
    max-width: auto;
  }
`;

export const StyledName = styled.h2`
  padding: 0;
  margin: 0 0 2.8rem 0;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: ${fontWeight.bold};
  color: ${colors.darkBlue};

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 2rem;
    line-height: 2.7rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
`;

export const StyledAddress = styled.span`
  display: block;
  margin: 0 0 2.8rem 0;
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: ${fontWeight.light};
  color: ${colors.silver};

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 2rem;
    line-height: 2.7rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
`;

export const StyledContacts = styled.span`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${fontWeight.light};
  color: ${colors.silver};

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  a {
    text-decoration: none;
    color: ${colors.silver};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledLinkedContainer = styled.a`
  text-decoration: none;
  max-width: 100%;
  width: 50rem;
  height: 30rem;
  box-shadow: 0.8rem 0.8rem 0 0 ${colors.butter};
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    height: 45rem;
    width: 63.6rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    height: 53.5rem;
  }
`;
