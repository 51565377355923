import React from 'react';
import Layout from 'templates/Layout/Layout';
import ContactForm from 'components/_shared/ContactForm/ContactForm';
import { Address, FormContainer } from 'components/Contact';
import ContentData from 'content/contact-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import PageHeading from 'components/_shared/PageHeading/PageHeading';

const Contact = () => (
  <Layout metaTitle="Skontaktuj się z nami!" metaDescription="Opisz swój pomysł, a my zajmiemy się resztą.">
    <PageHeading text="Kontakt" />
    <Address />
    <FormContainer>
      <ContactForm title="Wyślij nam wiadomość" />
    </FormContainer>
    <InformationOffice contentData={ContentData} />
  </Layout>
);

export default Contact;
