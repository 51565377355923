import styled from 'styled-components';
import { breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: 12rem;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding: 0 0 5rem 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 0rem 0 8rem 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 1.6rem 0 0 0;
    margin-bottom: -6.5rem;
  }
`;

export default StyledWrapper;
