import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledContainer } from './FormContainer.styled';

const FormContainer = ({ children }) => (
  <StyledWrapper>
    <StyledContainer>{children}</StyledContainer>
  </StyledWrapper>
);

FormContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FormContainer;
